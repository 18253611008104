<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="storeProductData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No products found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          products List
        </b-link>
        for other prducts.
      </div>
    </b-alert>

    <template v-if="storeProductData && storeProductFormData">
      <!-- First Row -->
      <b-row class="match-height">
        <b-col cols="12" sm="12" md="6">
          <b-card>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <h3 class="mb-2">Información del producto</h3>
              <!-- Form -->
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <!-- Variants -->
                <b-row
                  v-if="
                    storeProductData.store_product_variant_attributes &&
                    storeProductData.store_product_variant_attributes.length > 0
                  "
                >
                  <b-col>
                    <h5 class="mt-1 mr-1 mb-0">Variante:</h5>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-badge
                      v-for="(variant, n) in storeProductData
                        .store_product_variant_attributes.variants"
                      :key="`variant-${n}`"
                      class="mr-1 mt-50 mb-1"
                      pill
                      variant="primary"
                      size="sm"
                    >
                      {{ variant.variant_option_attributes.option_name }}
                    </b-badge>
                  </b-col>
                </b-row>

                <!-- Description -->
                <b-row>
                  <b-col>
                    <h5 class="mt-1 mr-1 mb-0">Descripción:</h5>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-textarea
                      v-model="storeProductFormData.description"
                      size="lg"
                    />
                  </b-col>
                </b-row>

                <!-- PRICE -->
                <validation-provider
                  #default="validationContext"
                  name="Precio"
                  rules="required"
                >
                  <b-form-group label="Precio" label-for="precio">
                    <b-input-group>
                      <b-input-group-prepend is-text> $ </b-input-group-prepend>
                      <b-form-input
                        id="precio"
                        v-model="storeProductFormData.unit_price"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        type="number"
                        step="0.01"
                        placeholder=""
                      />
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- COST -->
                <validation-provider
                  #default="validationContext"
                  name="Cost"
                  rules="required"
                >
                  <b-form-group label="Costo" label-for="cost">
                    <b-input-group>
                      <b-input-group-prepend is-text> $ </b-input-group-prepend>
                      <b-form-input
                        id="cost"
                        v-model="storeProductFormData.unit_cost"
                        :state="getValidationState(validationContext)"
                        trim
                        type="number"
                        step="0.01"
                      />
                    </b-input-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <div
                  class="d-flex justify-content-between align-items-center my-2"
                >
                  <label for="check-button">Visibilidad del producto</label>
                  <b-button-group size="sm">
                    <b-button
                      :variant="
                        !storeProductFormData.is_private
                          ? 'success'
                          : 'outline-secondary'
                      "
                      @click="storeProductFormData.is_private = false"
                    >
                      Publico
                    </b-button>
                    <b-button
                      :variant="
                        storeProductFormData.is_private
                          ? 'danger'
                          : 'outline-secondary'
                      "
                      @click="storeProductFormData.is_private = true"
                    >
                      Privado
                    </b-button>
                  </b-button-group>
                </div>
                <!-- INVENTORY -->
                <div>
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      my-2
                    "
                  >
                    <label for="check-button">Gestionar inventaro</label>
                    <b-form-checkbox
                      v-model="storeProductFormData.has_inventory"
                      name="check-button"
                      switch
                      inline
                      class="m-0"
                    />
                  </div>
                  <b-row
                    v-if="storeProductFormData.has_inventory"
                    class="border-top pt-2 pb-2"
                  >
                    <b-col class="d-flex justify-content-start">
                      <div class="text-center">
                        <h6 class="text-muted font-weight-bolder">
                          Existencia
                        </h6>
                        <h3>
                          {{ storeProductFormData.inventory }}
                        </h3>
                      </div>
                    </b-col>
                    <b-col sm="auto">
                      <div class="d-flex justify-content-end">
                        <b-button
                          v-if="!addProducts"
                          :disabled="removeProducts || isUnitsLoss"
                          size="sm"
                          class="mt-1"
                          variant="outline-primary"
                          @click="addProducts = true"
                        >
                          Agregar
                        </b-button>
                        <b-input-group v-else class="mt-1">
                          <b-form-input
                            v-model="unitsToAdd"
                            placeholder="Cantidad"
                            type="number"
                            size="sm"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-primary"
                              size="sm"
                              @click="addProducts = false"
                            >
                              <i class="fas fa-times" />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                      <div class="d-flex justify-content-end">
                        <b-button
                          v-if="!removeProducts"
                          :disabled="addProducts || isUnitsLoss"
                          size="sm"
                          class="mt-1"
                          variant="outline-primary"
                          @click="removeProducts = true"
                        >
                          Quitar
                        </b-button>
                        <b-input-group v-else class="mt-1">
                          <b-form-input
                            v-model="unitsToRemove"
                            placeholder="Cantidad"
                            type="number"
                            size="sm"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-primary"
                              size="sm"
                              @click="removeProducts = false"
                            >
                              <i class="fas fa-times" />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                      <div class="d-flex justify-content-end">
                        <b-button
                          v-if="!isUnitsLoss"
                          :disabled="addProducts || removeProducts"
                          size="sm"
                          class="mt-1"
                          variant="outline-primary"
                          @click="isUnitsLoss = true"
                        >
                          Perdidas
                        </b-button>
                        <b-input-group v-else class="mt-1">
                          <b-form-input
                            v-model="unitsLoss"
                            placeholder="Cantidad"
                            type="number"
                            size="sm"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-primary"
                              size="sm"
                              @click="isUnitsLoss = false"
                            >
                              <i class="fas fa-times" />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </b-col>
                  </b-row>
                </div>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-col cols="6">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      variant="success"
                      class="mr-0"
                      @click="isAddInventory()"
                    >
                      Aceptar
                    </b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      block
                      variant="outline-warning"
                      @click="cancelEdit()"
                    >
                      Cancelar
                    </b-button>
                  </b-col>
                </div>
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="danger"
                    class="mr-0"
                    @click="changeActiveStatus()"
                  >
                    Eliminar
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
        <b-col cols="12" sm="12" md="6">
          <store-product-info-card :product="storeProductData" />
        </b-col>
        <b-col cols="12">
          <promos-table-new />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import store from "@/store"
import router from "@/router"
import formValidation from "@core/comp-functions/forms/form-validation"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { ref, watch } from "@vue/composition-api"
import { mapActions } from "vuex"

import StoreProductInfoCard from "@/views/products/product-view/StoreProductInfoCard.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import PromosTableNew from "./PromosTableNew.vue"

import { required, alphaNum } from "@validations"

export default {
  components: {
    // Local Components
    StoreProductInfoCard,
    PromosTableNew,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  setup(context) {
    //store.dispatch('promotions/fetchPromotions', {by_product: router.currentRoute.params.store_product_id, by_is_canceled: false})

    const storeProductData = ref(null)
    const storeProductFormData = ref(null)
    store
      .dispatch(
        "storeProducts/fetchStoreProduct",
        router.currentRoute.params.store_product_id
      )
      .then((response) => {
        storeProductData.value = response
        storeProductFormData.value = structuredClone(response)
      })
    const resetStoreProductData = () => {
      storeProductFormData.value = storeProductData.value
    }

    const { getValidationState } = formValidation(resetStoreProductData)

    return {
      getValidationState,
      storeProductData,
      storeProductFormData,
      resetStoreProductData,
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      addProducts: false,
      unitsToAdd: 1,
      removeProducts: false,
      unitsToRemove: 0,
      isUnitsLoss: false,
      unitsLoss: 0,
      isLoan: false,
      editMode: true,
    }
  },
  methods: {
    ...mapActions("storeProducts", ["editStoreProduct"]),
    ...mapActions("products", ["fetchProduct"]),
    ...mapActions("orders", ["dispatchOrder"]),
    changeActiveStatus() {
      this.$swal({
        title: "¿Estás seguro?",
        text: "El producto será eliminado ¿Estás seguro que deseas continuar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "seee, borralo!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.storeProductFormData.active_status = false
          this.onSubmit()
        }
      })
    },
    isAddInventory() {
      this.onSubmit()
    },
    completeOrderDependingType() {
      let type = null
      if (this.addProducts) type = "buy"
      if (this.removeProducts) type = "inventory_correction"
      if (this.isUnitsLoss) type = "inventory_loss"
      this.handleAddInventory(type)
    },
    handleAddInventory(type) {
      let handleUnits
      if (this.addProducts) handleUnits = this.unitsToAdd
      if (this.removeProducts) handleUnits = this.unitsToRemove
      if (this.isUnitsLoss) handleUnits = this.unitsLoss

      this.dispatchOrder({
        order: {
          order_type: type,
          payment_type: "cash",
          is_loan: this.isLoan,
          store_id: this.$route.params.store_id,
          order_store_products_attributes: [
            {
              store_product_id: this.$route.params.store_product_id,
              units: handleUnits,
            },
          ],
          order_payments_attributes: [
            {
              payment_type: "cash",
              amount: handleUnits * this.storeProductFormData.unit_cost,
            },
          ],
        },
        orderType: type,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Orden completada!",
              icon: "CoffeeIcon",
              variant: "success",
              text: "La orden ha sido completada exitosamente",
            },
          })
          this.$router.push({
            name: "store-products",
            params: { id: this.$route.params.store_id },
          })
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.product[0],
            },
          })
          this.$router.push({
            name: "store-products",
            params: { id: this.$route.params.store_id },
          })
        })
    },
    cancelEdit() {
      this.$router.push({
        name: "store-products",
        params: { id: this.$route.params.store_id },
      })
    },
    onSubmit() {
      if (
        !this.addProducts &&
        !this.removeProducts &&
        !this.isUnitsLoss &&
        JSON.stringify(this.storeProductData) ===
          JSON.stringify(this.storeProductFormData)
      ) {
        this.$router.push({
          name: "store-products",
          params: { id: this.$route.params.store_id },
        })
      } else {
        this.editStoreProduct(this.storeProductFormData)
          .then(() => {
            if (this.addProducts || this.removeProducts || this.isUnitsLoss) {
              this.completeOrderDependingType()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Información actualizada!",
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: "El producto ha sido actualizado exitosamente",
                },
              })
              this.$router.push({
                name: "store-products",
                params: { id: this.$route.params.store_id },
              })
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error",
                icon: "CoffeeIcon",
                variant: "danger",
                text: error.response.data.messages.product[0],
              },
            })
          })
      }
    },
  },
}
</script>

<style></style>
