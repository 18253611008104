<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="horizontal"
      finish-button-text="Confirmar"
      next-button-text="Siguiente"
      back-button-text="Regresar"
      class="wizard-vertical mb-3"
      shape="square"
      step-size="sm"
      @on-complete="formSubmitted"
    >

      <!-- promo type -->
      <tab-content
        title="Tipo de promoción"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Tipo de promoción
              </h5>
              <small class="text-muted">
                Selecciona la promoción deseada
              </small>
            </b-col>
          </b-row>
          <b-row>
            <b-col >
              <b-card  border-variant="primary" class="cursor-pointer" :bg-variant="promo.promo_type === 'price_discount' ? 'primary' : ''"
                @click="promo.promo_type = 'price_discount'"
              >
                <b-card-body class="promo-card d-flex align-items-center justify-content-center">
                  Descuento
                </b-card-body>
              </b-card>
            </b-col>
            <b-col>
              <b-card class="cursor-pointer" border-variant="primary" :bg-variant="promo.promo_type === 'free_product' ? 'primary' : ''"
                @click="promo.promo_type = 'free_product'"
              >
                <b-card-body class="promo-card d-flex align-items-center justify-content-center">
                  Producto gratis
                </b-card-body>
              </b-card>
            </b-col>
            <b-col>
              <b-card class="cursor-pointer" border-variant="primary" :bg-variant="promo.promo_type === 'reward_points' ? 'primary' : ''"
                @click="promo.promo_type = 'reward_points'"
              >
                <b-card-body class="promo-card d-flex align-items-center justify-content-center">
                  Puntos de recompensa
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- logic -->
      <tab-content
        title="Configuración"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row v-if = "promo.promo_type === 'free_product'">
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Configuración de la promoción
              </h5>
              <small class="text-muted">Ingresa las cantidades adecuadas para tu promoción. Si eliges que cuando te compran 3 unidades y regalas 1, estarás regalándole una unidad del producto a tu cliente</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cuando te compran"
                label-for="needs_to_buy"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cuando te compran"
                  rules="required"
                >
                <b-form-input
                  id="needs_to_buy"
                  v-model="promo.needs_to_buy"
                  placeholder="1"
                  :state="errors.length > 0 ? false:null"
                  size="lg"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Regalas"
                label-for="last-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Regalas"
                  rules="required"
                >
                  <b-form-input
                    id="first-name"
                    v-model="promo.gets_you"
                    placeholder="2"
                    :state="errors.length > 0 ? false:null"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if = "promo.promo_type === 'price_discount'">
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Configuración General
              </h5>
              <small class="text-muted">Ingresa la cantidad mínima para que se aplique la promoción y el porcentage de descuento</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cuando te compran"
                label-for="needs_to_buy"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cuando te compran"
                  rules="required"
                >
                <b-form-input
                  id="needs_to_buy"
                  v-model="promo.needs_to_buy"
                  placeholder="1"
                  :state="errors.length > 0 ? false:null"
                  size="lg"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Descuento %"
                label-for="price_discount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Descuento"
                  rules="required"
                >
                <b-form-input
                  id="price_discount"
                  v-model="promo.discount"
                  placeholder="10%"
                  :state="errors.length > 0 ? false:null"
                  size="lg"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if = "promo.promo_type === 'reward_points'">
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Configuración General
              </h5>
              <small class="text-muted">Ingresa la cantidad mínima para que se aplique la promoción y el porcentage de puntos de recompens que otorgarás. Si el precio de un producto es
                $10.00 MXN y das el 10% de recompensa, les estarás regalando $1.00 MXN en puntos
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cuando te compran"
                label-for="needs_to_buy"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cuando te compran"
                  rules="required"
                >
                <b-form-input
                  id="needs_to_buy"
                  v-model="promo.needs_to_buy"
                  placeholder="1"
                  :state="errors.length > 0 ? false:null"
                  size="lg"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Puntos de recompensa %"
                label-for="reward_points"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Reward points"
                  rules="required"
                >
                <b-form-input
                  id="reward_points"
                  v-model="promo.reward_points"
                  placeholder="10%"
                  :state="errors.length > 0 ? false:null"
                  size="lg"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

        </validation-observer>
      </tab-content>

      <!-- From-to date promotion -->
      <tab-content
        title="Validez"
        :before-change="validationFormDates"
      >
        <validation-observer
          ref="datesValidity"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Fecha valida de promoción
              </h5>
              <small class="text-muted">Ingresa las fechas para las cuales tu promoción estará vigente</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Fecha inicial"
                label-for="begins_at"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fecha inicial"
                  rules="required"
                >
                <b-form-input
                  type="date"
                  id="begins_at"
                  v-model="promo.begins_at"
                  placeholder="1"
                  :state="errors.length > 0 ? false:null"
                  size="lg"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Fecha final"
                label-for="ends_at"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fecha final"
                  rules="required"
                >
                <b-form-input
                  type="date"
                  id="ends_at"
                  v-model="promo.ends_at"
                  placeholder="10%"
                  :state="errors.length > 0 ? false:null"
                  size="lg"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

        </validation-observer>
      </tab-content>

    </form-wizard>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import '@/@core/scss/vue/libs/vue-wizard.scss'
import BaseCropper from '@/@core/components/BaseCropper.vue'
// import { StreamBarcodeReader } from 'vue-barcode-reader'
import { QrcodeStream } from 'vue-qrcode-reader'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BCardBody,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    vSelect,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BaseCropper,
    // StreamBarcodeReader,
    QrcodeStream,
  },
  data() {
    return {
      promo: {
        begins_at: "",
        ends_at: "",
        promo_type: "free_product", // 'reward_points' and 'price_discount'
        needs_to_buy: 2,
        gets_you: 1,
        discount: 0,
        reward_points: 0,
        store_id: this.$route.params.store_id,
        store_product_ids: [],
      },
      walleat: {
        name: '',
        establishments_ids: [],
        daily_limit: '',
        bracelet_number: '',
        qr_scan_result: [],
        logo: null,
      },
      establishments: [],
      required,
      email,
      walleatIdDelimiter: {
        delimiters: [':', ':', ':', ':', ':', ':', ':', ':'],
        blocks: [2, 2, 2, 2, 2, 2, 2, 2, 2],
        uppercase: true,
      },

    }
  },
  beforeMount() {
    this.fetchEstablishments({
      meta: {
        pagination: {
          per_page: 1000,
        },
      },
    })
      .then(response => {
        this.establishments = response.data
      })
  },
  methods: {
    ...mapActions('walleats', ['addWalleat']),
    ...mapActions('establishments', ['fetchEstablishments']),
    ...mapActions('promotions', ['createPromotion']),
    formSubmitted() {
      // Create Promotion
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.promo.needs_to_buy = +this.promo.needs_to_buy
      this.promo.gets_you = +this.promo.gets_you
      this.promo.store_product_ids = [this.$route.params.store_product_id]
      this.createPromotion(this.promo).then(res=>{
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Form Submitted',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Promoción creada',
          },
        })
        this.$emit('promotionCreated')
      })
      .catch(error => {
        const baseMsg = error.response.data.messages?.base ?? null
        const freeProductMsg = error.response.data.messages?.free_product ?? null
        const rewardPointsMsg = error.response.data.messages?.reward_points ?? null
        const datesMsg = error.response.data.messages?.dates ?? null
        const discountMsg = error.response.data.messages?.price_discount ?? null
        const invalidUpdateMsg = error.response.data.messages?.invalid_update ?? null
        const invalidPromotionPublishingMsg = error.response.data.messages?.invalid_promotion_publishing ?? null
        if (baseMsg != null) {
          this.$swal({
            title: 'ERROR!',
            text: error.response.data.messages.base[0],
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        if (freeProductMsg != null) {
          this.$swal({
            title: 'ERROR!',
            text: error.response.data.messages.free_product[0],
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        if (datesMsg != null) {
          this.$swal({
            title: 'ERROR!',
            text: error.response.data.messages.dates[0],
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        if (invalidPromotionPublishingMsg != null) {
          this.$swal({
            title: 'ERROR!',
            text: error.response.data.messages.invalid_promotion_publishing[0],
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        if (rewardPointsMsg != null) {
          this.$swal({
            title: 'ERROR!',
            text: error.response.data.messages.reward_points[0],
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        if (discountMsg != null) {
          this.$swal({
            title: 'ERROR!',
            text: error.response.data.messages.price_discount[0],
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        if (invalidUpdateMsg != null) {
          this.$swal({
            title: 'ERROR!',
            text: error.response.data.messages.invalid_update[0],
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        baseMsg =  null
        freeProductMsg  =  null
        rewardPointsMsg  =  null
        datesMsg  =  null
        discountMsg  =  null
        invalidUpdateMsg  =  null
        invalidPromotionPublishingMsg  =  null
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormDates() {
      return new Promise((resolve, reject) => {
        this.$refs.datesValidity.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    onDecode(result) {
      this.qr_scan_result = result.split('/')
      this.walleat.bracelet_number = this.qr_scan_result[this.qr_scan_result.length - 1].toString()
      this.$bvModal.hide('scanner')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.promo-card {
  height: 120px;
}

</style>
